<template>
  <div class="animated fadeIn">

    <b-card>
      <h3 class="mb-2">
        {{ $route.meta.breadcrumb[1].text }}
      </h3>
      <b-row>
        <b-col :lg="models.items.length ? 8 : 12">
          <b-button-group>
            <b-button
              :disabled="models.type !== 'Produk'"
              :variant="models.state === 'produk' ? 'primary' : 'link'"
              class="px-4"
              @click="changeState('produk')"
            >
              Produk
            </b-button>
            <b-button
              :disabled="models.type === 'Produk'"
              :variant="models.state !== 'produk' ? 'primary' : 'link'"
              class="px-4"
              @click="changeState('bahan baku')"
            >
              Bahan Baku
            </b-button>
          </b-button-group>

          <div class="col-12 px-0 order-1 order-md-0 d-flex flex-column flex-md-row mt-1">
            <div
              class="input-group px-0"
            >
              <div class="input-group-prepend">
                <div
                  class="input-group-text"
                  @click="search"
                >
                  <feather-icon icon="SearchIcon" />
                </div>
              </div>
              <input
                v-model="config.search"
                type="text"
                placeholder="Search"
                class="form-control"
                @keyup.enter="search"
              >
            </div>
          </div>

          <b-row
            class="mt-1"
          >
            <b-col
              v-for="item,idx in items"
              :key="idx"
              md="3"
            >
              <div class="p-1 border shadow text-center mb-2">
                <div
                  style="height: 110px; width: 100%"
                  class="mb-1"
                >
                  <b-img
                    :src="getUrlOfFile(item.detailItemImage[0], true)"
                    :alt="item.name"
                    style="object-fit: cover"
                    class="w-100 h-100 mb-1 rounded"
                  />
                </div>
                <h5>{{ item.name }}</h5>
                <span>Rp. {{ parseFloat(item.unitPrice).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}</span>
                <div class="d-flex align-items-center justify-content-center mt-1">
                  <b-button
                    size="sm"
                    variant="secondary"
                    style="padding: 7px; line-height: 7px"
                    class="rounded-circle"
                    @click="removeProduct(item)"
                  >
                    -
                  </b-button>
                  <p class="px-1 mb-0">
                    {{ renderQty(item.id) }}
                  </p>
                  <b-button
                    size="sm"
                    variant="primary"
                    style="padding: 7px; line-height: 7px"
                    class="rounded-circle"
                    @click="pushProduct(item)"
                  >
                    +
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="models.items.length"
          lg="4"
        >
          <div
            class="d-flex justify-content-between border p-1 position-relative pr-2"
            :class="{'border-bottom-0': visible}"
            @click="visible = !visible"
          >
            <p class="mb-0 font-weight-bold">
              {{ models.items.length }} Terpilih
            </p>
            <p class="mb-0 font-weight-bold">
              Rp. {{ parseFloat(totalPrice).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
            </p>
            <span
              class="position-absolute arrow"
              :class="{'rotate': visible}"
              style="right: 6px; top: 10px"
            >></span>
          </div>
          <b-collapse
            id="collapse-4"
            v-model="visible"
          >
            <div
              v-for="model, idx in models.items"
              :key="`item-${idx}`"
              class="border border-top-0 border-bottom-0 p-1 d-flex justify-content-between"
            >
              <p class="mb-0">
                {{ model.name || model.detail.name }} ({{ model.qty || 0 }})
              </p>
              <p class="mb-0">
                Rp. {{ parseFloat(model.unitPrice * model.qty).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') }}
              </p>
            </div>
          </b-collapse>
          <b-button
            variant="primary"
            class="btn-min-width mt-1 w-100"
            @click="save"
          >
            Tambah
          </b-button>
          <b-button
            type="button"
            variant="light"
            class="rounded btn-secondary w-100"
            style="margin-top: 5px"
            @click="goBack"
          >
            Kembali
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButtonGroup, BButton, BRow, BCol, BCollapse, BImg,
} from 'bootstrap-vue'
import { getUrlOfFile } from '@/utils/helpers'

import api from '@/utils/api'

export default {
  name: 'FormPOProduct',
  metaInfo: {
    title: 'Add Additional Product',
  },
  components: {
    BCard,
    BButtonGroup,
    BButton,
    BRow,
    BCol,
    BCollapse,
    BImg,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
        search: '',
      },
      masters: {
        products: [],
        rawMaterials: [],
      },
      models: {
        state: 'produk',
        items: [],
        type: '',
      },
      kfsId: null,
      visible: true,
      getUrlOfFile,
    }
  },
  computed: {
    items() {
      return this.models.state === 'produk' ? this.masters.products : this.masters.rawMaterials
    },
    totalPrice() {
      return this.models.items.map(item => item.unitPrice * item.qty).reduce((total, amount) => total + amount, 0)
    },
  },
  mounted() {
    this.getListData().then(() => {
      if (this.$route.params.items) {
        this.models.items = this.$route.params.items
      }
      if (this.$route.params.kfsId) {
        this.kfsId = this.$route.params.kfsId
      }
      if (this.$route.params.type) {
        this.models.type = this.$route.params.type
        this.models.state = this.$route.params.type === 'Produk' ? 'produk' : 'bahan baku'
      } else if (this.$route.params.id && !this.$route.params.type) {
        return this.$axios.get(`${api.purchaseOrder}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.kfsId = data.kfsId
            this.models.type = data.typeText
            this.models.state = data.typeText === 'Produk' ? 'produk' : 'bahan baku'
            data.products.forEach(product => {
              product.id = product.accuProductId
              this.models.items.push(product)
            })
            data.raws.forEach(raw => {
              raw.id = raw.accuRawMaterialId
              this.models.items.push(raw)
            })
          })
          .catch(() => this.$router.push({
            name: 'page404',
          }))
      }
      return true
    }).then(() => this.validatePO())
  },
  methods: {
    search() {
      const apiEndpoint = this.models.state === 'produk' ? api.product : api.rawMaterial
      this.$axios.get(apiEndpoint, {
        params: {
          search: this.config.search,
        },
      })
        .then(res => {
          if (this.models.state === 'produk') {
            this.masters.products = res.data.data.rows
          } else {
            this.masters.rawMaterials = res.data.data.rows
          }
        })
        .then(() => this.validatePO())
    },
    getListData() {
      const getProducts = this.$axios.get(api.product)
      const getRawMaterials = this.$axios.get(api.rawMaterial)

      return Promise.all([getProducts, getRawMaterials]).then(res => {
        const [product, materials] = res
        this.masters.products = product.data.data.rows
        this.masters.rawMaterials = materials.data.data.rows
      })
    },

    goBack() {
      this.$router.push({
        name: 'detail-purchase-order',
        params: {
          id: this.$route.params.id,
        },
      })
    },

    renderQty(id) {
      const itemIdx = this.models.items.findIndex(model => model.id === id)
      return itemIdx > -1 ? this.models.items[itemIdx].qty : 0
    },

    pushProduct(item) {
      const itemIdx = this.models.items.findIndex(model => model.id === item.id)
      if (itemIdx > -1) {
        this.models.items[itemIdx].qty += 1
        this.models.items.push(item)
        this.models.items.pop()
      } else {
        item.qty = 1
        this.models.items.push(item)
      }
    },

    removeProduct(item) {
      const itemIdx = this.models.items.findIndex(model => (model.id === item.id))
      if (itemIdx > -1) {
        if (this.models.items[itemIdx].qty > 0) {
          this.models.items[itemIdx].qty -= 1
          if (this.models.items[itemIdx].qty === 0) {
            this.models.items = this.models.items.filter((item, idx) => idx !== itemIdx)
          }
        }

        this.models.items.push(item)
        this.models.items.pop()
      }
    },

    changeState(type) {
      if (this.models.type.toLowerCase() === type) {
        this.models.state = type
      }
    },

    save() {
      this.$router.push({
        name: 'detail-purchase-order',
        params: {
          id: this.$route.params.id,
          items: this.models.items,
        },
      })
    },

    validatePO() {
      const type = this.models.type === 'Produk' ? 1 : 2
      const body = { type, kfsId: this.kfsId }
      if (this.models.type === 'Produk') {
        body.products = this.masters.products.map(product => ({
          accuProductId: product.id,
          qty: 1,
        }))
      } else {
        body.raws = this.masters.rawMaterials.map(raw => ({
          accuRawMaterialId: raw.id,
          qty: 1,
        }))
      }
      this.$axios.post(`${api.purchaseOrder}/validation`, body)
        .then(res => {
          // Adjust price item to follow level kfs
          const { data } = res.data
          if (this.models.type === 'Produk') {
            this.masters.products = this.masters.products.map(item => {
              const findItem = data.products.find(product => product.accuProductId === item.id)
              if (findItem) {
                item.unitPrice = findItem.unitPrice
              }
              return item
            })
          } else {
            this.masters.rawMaterials = this.masters.rawMaterials.map(item => {
              const findItem = data.raws.find(raw => raw.accuRawMaterialId === item.id)
              if (findItem) {
                item.unitPrice = findItem.unitPrice
              }
              return item
            })
          }
        })
    },
  },
}
</script>

<style>

</style>
